<div class="bg-light-blue px-2 mb-4 border-dark border border-2 equal-grid">
  <div>
    <p class="text-center m-3">
      <a [routerLink]="['/nb-plus']">
        <img
          src="../../../assets/img/nb-plus/nb-plus-logo.svg"
          alt="NB Plus logo"
          class="img-fluid"
        />
      </a>
    </p>
  </div>
  <div>
    <p class="text-center h6 mt-3">
      If you are one of our loyal NB Plus subscribers, you will receive a huge
      exclusive discount on all NB Medical Physical Books
    </p>
    <p class="text-center">
      <span class="text-primary fw-bold h4">
        Buy any physical book for
        {{ nbPlusBookPrice | currency : "GBP" }}*</span
      >.
    </p>

    <p class="text-center">
      <nbm-cta-button [routerLink]="['/nb-plus']">FIND OUT MORE</nbm-cta-button>
    </p>
    <p class="text-center"><small>* Discount applied at checkout</small></p>
  </div>
</div>
