<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>
<a
  *ngIf="href; else internal"
  [href]="href"
  [class]="
    'text-dark btn btn-white border-dark border text-uppercase ' +
    additionalClasses
  "
  [ngClass]="{ 'btn-lg': largeButton }"
>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</a>
<ng-template #internal>
  <a
    *ngIf="routerLink; else actionButton"
    [routerLink]="routerLink"
    [class]="
      'text-dark btn btn-white border-dark border text-uppercase ' +
      additionalClasses
    "
    [ngClass]="{ 'btn-lg': largeButton }"
  >
    <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
  </a>
  <ng-template #actionButton>
    <button
      [class]="
        'text-dark btn btn-white border border-dark text-uppercase ' +
        additionalClasses
      "
      [ngClass]="{ 'btn-lg': largeButton }"
      (click)="clicked.emit()"
    >
      <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </button>
  </ng-template>
</ng-template>
