<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>
<a
  *ngIf="routerLink; else actionButton"
  [routerLink]="routerLink"
  [fragment]="fragment"
  [class]="'btn  text-uppercase btn-' + btnType + ' ' + additionalClasses"
  [ngClass]="{ 'btn-lg': largeButton }"
>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</a>
<ng-template #actionButton>
  <button
    [class]="'btn text-uppercase btn-' + btnType + ' ' + additionalClasses"
    [ngClass]="{ 'btn-lg': largeButton }"
    (click)="clicked.emit()"
  >
    <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
  </button>
</ng-template>
